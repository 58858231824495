import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'
import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Markdown from '@base/parts/markdown'
import AnimatedBackground from '@base/elements/animated-background'
import TweetCard from '../elements/tweet-card'
import twitterIcon from '../../assets/icons/x-corp.svg'

const SocialBlock = ({ data }) => {
  const [tweetList, setTweetList] = useState(null)

/*   function getTweets() {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    let raw = JSON.stringify({
      user_id: '1532786033460396033',
      max_results: 5,
    })
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: raw,
      redirect: 'follow',
    }
    return fetch('http://localhost:3000/getTweets', requestOptions)
      .then((response) => response.json())
      .then(function (json) {
        return json
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  } */

  useEffect(() => {
/*     getTweets().then(function (result) {
      if (result) {
        setTweetList(result)
      }
    }) */
  }, [data])

  return (
    <section className="relative" data-section={data.section}>
      <div className="mask mask-offset-left gradient-dark-midnight gradient-corner relative box-border flex h-full w-full flex-col items-center justify-center overflow-hidden px-6 pb-96 lg:px-0 lg:pb-72">
        <div id={data.section} className="absolute -top-16 lg:-top-4"></div>
        <ScrollDisplay className="mb-48 flex w-full flex-col items-center lg:mb-0">
          <h2 className="relative z-10 w-full pt-16 pb-8 text-center text-primary">
            <Markdown children={data.title} />
            <Markdown children={data.title2} />
          </h2>
          <a
            href="https://twitter.com/FlippinMisfits"
            className="social-links-item-round"
            target="_blank"
            rel="noreferrer"
          >
            <div className="social-link flex max-w-[100px] items-center justify-center text-white">
              <SVG
                onError={(error) => console.log(error.message)}
                cacheRequests={true}
                preProcessor={(code) =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={twitterIcon}
                className=""
              />
            </div>
          </a>
        </ScrollDisplay>

        <div className="relative z-10 box-border flex w-full justify-center px-0 lg:w-3/4">
          <AnimatedBackground className="pointer-events-none absolute -left-12 top-0 z-10 block h-auto w-[250px] lg:hidden">
            <StaticImage
              src="../../assets/img/flippin-misfits-graffiti-2.png"
              alt="Flippin Misfits Graffiti"
              className="anim-float h-full w-full"
              loading="lazy"
            />
          </AnimatedBackground>

          {tweetList && tweetList !== null && (
            <ScrollDisplay
              className="social-twitter-feed relative z-10 mt-12 flex w-full flex-col overflow-hidden rounded-[30px] border-8 border-solid border-pinkDark bg-white px-6 py-5 lg:flex-row lg:space-x-20 lg:px-20"
              delay="0.5"
            >
              {
                // Since Twitter's API can't return less than 5 tweets, we shall only keep and display the latest 3 (even if that's dumdum)
                tweetList.data &&
                  tweetList.data
                    .slice(0, 3)
                    .map((tweet) => (
                      <TweetCard
                        data={tweet}
                        image={tweetList.includes.media.filter(
                          (obj) =>
                            obj.media_key ===
                            (tweet.attachments
                              ? tweet.attachments.media_keys[0]
                              : null)
                        )}
                        className="mb-14 w-full border-x-0 border-t-0 border-b border-solid border-[#1da1f2] py-12 font-body text-sm font-normal text-blueDark lg:w-1/3"
                      />
                    ))
              }
            </ScrollDisplay>
          )}

          <AnimatedBackground
            className="pointer-events-none absolute right-0 -bottom-48 z-0 h-auto w-[250px] xl:-right-72 xl:-bottom-20 xl:w-[400px]"
            translate={[200, 0]}
          >
            <StaticImage
              src="../../assets/img/flippin-misfits-graffiti-4.png"
              alt="Flippin Misfits Graffiti"
              className="anim-float h-full w-full"
              loading="lazy"
            />
          </AnimatedBackground>
        </div>
      </div>
    </section>
  )
}

export default SocialBlock
