import React from 'react'
import { motion } from 'framer-motion'
import { CarouselWrapper, CarouselContent, CarouselTabs } from '@bhvr/web-design-system'
import { Gallery, Item } from 'react-photoswipe-gallery'

import ScrollDisplay from '@base/utils/helpers/scroll-display'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'

const GallerySlider = ({ data, title, text }) => {

  const windowSize = useWindowSize()
  let isMobile = false
  if (windowSize.width <= 991) {
    isMobile = true
  }

  return (
    <CarouselWrapper>
      <ScrollDisplay
        type="blur"
        delay={0.25}
        className="w-full lg:w-[48%] box-border pr-8 lg:pr-0"
      >
        <Gallery>
          <CarouselContent
            slidesPerView="3"
            loop
            autoplay={true}
            effect={"fade"}
            speed={750}
            allowTouchMove={true}
            preloadImages={false}
            lazy={{ loadPrevNext: true }}
            className="gallery-slider-image h-full"
            slideClassName="gallery-slide-image ratio relative h-auto"
          >
            {data.map((slide, i) => (
              <Item
                original={slide.image.publicURL}
                thumbnail={slide.image.publicURL}
                width="1280"
                height="720"
              >
                {({ ref, open }) => (
                  <div
                    ref={ref}
                    onClick={open}
                    className="absolute top-0 w-full h-full flex justify-center cursor-zoom-in"
                  >
                    <Image
                      media={slide.image}
                      className="gatsby-absolute -top-[5%] h-[110%]"
                    />
                  </div>
                )}
              </Item>
            ))}
          </CarouselContent>
        </Gallery>
      </ScrollDisplay>
      <ScrollDisplay
        className="gallery-slider-thumbs-wrapper w-full lg:w-[52%] h-full xxl:h-auto relative lg:pl-32 mt-20 flex flex-col justify-center overflow-hidden"
        delay={0.5}
      >
        <div className="w-[90%] mx-auto order-2 lg:order-1">
          <h3 className="mb-12 text-center lg:text-left">
            <Markdown children={title} />
          </h3>
          <p className="text-white leading-relaxed text-center lg:text-left">
            <Markdown children={text} />
          </p>
        </div>
        {/* <CarouselContent
          slidesPerView="1"
          loop
          effect={"fade"}
          allowTouchMove={true}
          className="gallery-slider-text h-full w-full"
          slideClassName="gallery-slide-text pt-24"
        >
          {data.map((slide, i) => (
            <>
              <h3>
                <Markdown children={slide.title} />
              </h3>
              <p className="text-white">
                <Markdown children={slide.description} />
              </p>
            </>
          ))}
        </CarouselContent> */}
        <CarouselTabs
          tabsPerView="3"
          className="gallery-slider-thumbs w-[90%] h-auto pb-20 lg:py-12 lg:mt-12 order-1 lg:order-2"
          loop
          autoplay={true}
          centeredSlides={true}
          slideClassName="gallery-slide-thumb cursor-pointer w-1/4 ratio-fourth relative h-auto"
          spaceBetween={isMobile ? 30 : 50}
        >
          {data.map((thumb, i) => (
            <motion.div
              initial={{ opacity: 0, top: 20 }}
              animate={{ opacity: 1, top: 0 }}
              exit={{ opacity: 0, top: 20 }}
              transition={{
                duration: 0.75,
              }}
              className="absolute top-0 w-full h-full flex justify-center"
            >
              <Image
                media={thumb.image}
                className="gatsby-absolute -top-[5%] h-[110%]"
              />
            </motion.div>
          ))}
        </CarouselTabs>
      </ScrollDisplay>
    </CarouselWrapper>
  )
}

export default GallerySlider
