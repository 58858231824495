import React, { useRef, useState } from 'react'
import { CarouselWrapper, CarouselContent, CarouselControls, Video } from '@bhvr/web-design-system'

import ScrollDisplay from '@base/utils/helpers/scroll-display'

const FeaturesSlider = ({ data }) => {
  const featuresRef = useRef(null)
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState([1000, false]) // video index (int.) and pause state (bool)

  function playVideo(index) {
    setCurrentVideoPlaying([index, false])
    featuresRef.current.childNodes[0].childNodes[0].childNodes.forEach((feature) => {
      let dataIndex = feature.querySelector('.feature-slide').getAttribute('data-index')
      let featurePlayBtn = feature.querySelector('.feature-play')
      let featureVideo = feature.querySelector('video')

      if (parseInt(dataIndex) === parseInt(index)) {
        if (featurePlayBtn.classList.contains('playing')) {
          // Pause Video
          setCurrentVideoPlaying([index, true])
          featureVideo.pause()
        } else {
          // Play video
          setCurrentVideoPlaying([index, false])
          featureVideo.play()
          console.log('play' + featureVideo)
        }
      } else { // Pause every other videos
        featureVideo.pause()
      }
    })
  }

  return (
    <CarouselWrapper>
      <div ref={featuresRef} className="w-full box-border relative block lg:hidden">
        <CarouselContent
          slidesPerView="1"
          loop
          autoplay={true}
          speed={750}
          allowTouchMove={true}
          preloadImages={false}
          lazy={{ loadPrevNext: true }}
          className="features-slider"
        >
          {data.map((feature, i) => (
            <div className="feature-slide w-full p-10 box-border" data-index={i}>
              <ScrollDisplay className="feature-image-wrapper" >
                <div className="feature-image">
                  <Video
                    autoplay={true}
                    loading="lazy"
                    loop={true}
                    muted={true}
                    player="self"
                    className="video-embed corners-content drop-shadow-md bg-black absolute top-0 h-full w-full"
                    source={feature?.video?.publicURL}
                    poster={feature?.poster?.publicURL}
                  />
                  <button
                    className={`feature-play absolute w-full h-full left-0 top-0 cursor-pointer z-10 ${currentVideoPlaying[0] === i && currentVideoPlaying[1] === false ? 'playing' : 'paused'}`}
                    onClick={() => playVideo(i)}
                  >
                    <div className="feature-play-btn">
                      <svg viewBox="0 0 100 100">
                        <mask id={`button-mask-${i}`}>
                          <rect x="0" y="0" width="100" height="100" fill="white" />
                          <g transform="translate(20, 20) scale(1.65)">
                            <path d='M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28' fill="black" />
                          </g>
                        </mask>
                        <circle
                          className="fill-white"
                          cx="50"
                          cy="50"
                          r="50"
                          mask={`url(#button-mask-${i})`}
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </ScrollDisplay>
              <ScrollDisplay className="lg:px-10">
                <h3 className="font-display mt-20 mb-6">{feature.title}</h3>
                <p>{feature.description}</p>
              </ScrollDisplay>
            </div>
          ))}
        </CarouselContent>
        <CarouselControls
          className="features-slider-nav"
          nextLabel='Next'
          nextClass='swiper-button-next'
          prevLabel='Previous'
          prevClass='swiper-button-prev'
        />
      </div>
    </CarouselWrapper >
  )
}

export default FeaturesSlider
