import React from 'react'
import Markdown from '@base/parts/markdown'
import AnimatedDots from '@elements/animated-dots'
import ScrollDisplay from '@base/utils/helpers/scroll-display'
import GallerySlider from '@elements/gallery-slider'
import { StaticImage } from 'gatsby-plugin-image'
import AnimatedBackground from '@base/elements/animated-background'

const GalleryBlock = ({ data, pageContext }) => {
  return (
    <section
      className="relative gradient-dark-midnight gradient-corner-reverse"
      data-section={data.section}
    >
      <div className="relative h-full w-full overflow-hidden flex mask-reverse mask-offset-right bg-blueDark flex-col items-center pb-52">
        <div id={data.section} className="absolute -top-16 lg:-top-20"></div>
        <ScrollDisplay className="flex flex-col items-center w-full">
          <h2 className="text-primary text-center relative z-10 px-10 py-20 lg:p-24 ">
            <Markdown
              children={data.title}
            />
            <Markdown
              children={data.title2}
            />
          </h2>
        </ScrollDisplay>
        <div className="gradient-dark-midnight gradient-round w-full lg:w-3/4 relative z-10 flex flex-col lg:flex-row px-10 lg:px-0 box-border">
          <GallerySlider
            data={data.gallery}
            title={data.subtitle}
            text={data.text}
          />
        </div>
        <ScrollDisplay className="w-full gatsby-hidden-mobile" delay="0.25">
          <AnimatedDots className="dots-bg dots-bg-bottom" />
        </ScrollDisplay>
      </div>
      <AnimatedBackground className="absolute w-[300px] xxl:w-[400px] h-auto left-0 -bottom-72 xxl:-bottom-96 pointer-events-none z-10 hidden lg:block">
        <StaticImage
          src="../../assets/img/flippin-misfits-graffiti-2.png"
          alt="Flippin Misfits Graffiti"
          className="h-full w-full anim-float"
          loading="lazy"
        />
      </AnimatedBackground>
    </section>
  )
}

export default GalleryBlock
