import React from 'react'
//import * as blocks from '../blocks'
import HeroBlock from '../blocks/hero'
import GalleryBlock from '../blocks/gallery'
import SocialBlock from '../blocks/social'
import FeaturesBlock from '../blocks/features'
import CharactersBlock from '../blocks/characters'

// Map Strapi blocks to section components
const blockComponents = {
  'sections.hero': HeroBlock,
  'sections.gallery': GalleryBlock,
  'sections.social': SocialBlock,
  'sections.features': FeaturesBlock,
  'sections.characters': CharactersBlock
}

// Display a section individually
const Block = ({ pageContext, sectionData, locale }) => {
  let componentName
  let BlockComponent
  componentName = sectionData.strapi_component ? sectionData.strapi_component : sectionData.data_component
  BlockComponent = blockComponents[componentName]

  if (!BlockComponent) {
    return null
  }

  // Display the section
  return (
    <BlockComponent
      pageContext={{ ...pageContext }}
      data={sectionData}
      locale={locale}
    />
  )
}

// Display the list of blocks
const Blocks = ({ pageContext, blocks, locale }) => {
  if (blocks !== null) {
    return (
      <>
        {blocks.map((section) => (
          <Block
            pageContext={{ ...pageContext }}
            sectionData={section}
            locale={locale}
            key={section.id}
          />
        ))}
      </>
    )
  }
  return ''
}

export default Blocks
