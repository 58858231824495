/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'

import { PageContext } from '@base/utils/contexts/page-context'
import PostContextProvider from '@base/utils/contexts/posts-context'
import { extendPageContext } from '@base/utils/helpers/extend-context'
import PageLayout from '@base/layout/page-layout'

import Blocks from '@layout/blocks'
import { getModalData } from '@/content/api/modal-data'

const DynamicPage = ({ data, pageContext }) => {
  const { currentPage } = useContext(PageContext)
  const [currentPageContext, setCurrentPageContext] = currentPage
  const localizations = data.pageData.localizations
  const parentPage = null
  const postType = 'page'
  const { contentSections, metadata, title, disablePageBreadcrumbs, disableNewsletterSignup, disableBuyGameSection, locale } = data.pageData
  const gameData = pageContext.globalData.game.edges[0].node
  const extendedPageContext = extendPageContext(title, postType, metadata, pageContext)
  const modalData = getModalData(pageContext.globalData)

  useEffect(() => {
    setCurrentPageContext(extendedPageContext)
  }, [])

  return (
    <PageLayout
      pageContext={{ ...pageContext, localizations, title, postType }}
      modalData={modalData}
      disabledNews={disableNewsletterSignup}
      disabledBuy={disableBuyGameSection}
      breadcrumbs={{
        pageBreadcrumbs: disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        globalBreadcrumbs: true
      }}
    >
      <PostContextProvider>
        <Blocks
          pageContext={{ ...extendedPageContext, localizations, gameData }}
          blocks={contentSections}
          locale={locale}
        />
      </PostContextProvider>
    </PageLayout>
  )
}
export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($id: String!) {
    pageData: pagesJson(id: {eq: $id}) {
      dataId
      locale
      localizations {
        dataId
        locale
        slug
        publishedAt
      }
      slug
      title
      disablePageBreadcrumbs
      disableBuyGameSection
      disableNewsletterSignup
      contentSections {
        id
        section
        title
        title2
        subtitle
        text
        button {
          text
          link
          icon {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
          trigger
        }
        button2 {
          text
          link
          icon {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
          trigger
        }
        keyart {
          video {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
          poster {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        logo {
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          publicURL
        }
        gallery {
          title
          description
          image {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        features {
          title
          description
          video {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
          poster {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        characters {
          image {
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        data_component
      }
      metadata {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`
export { Head } from '@base/layout/head'