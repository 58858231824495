import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'
import { Video } from '@bhvr/web-design-system'

import useWindowSize from '@base/utils/hooks/useWindowSize'
import MixedMedia from '@base/parts/mixed-media'
import AnimatedBackground from '@base/elements/animated-background'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'
import Promotion from '@base/elements/store/promo-tag'

const HeroBlock = ({ data, pageContext }) => {
  const windowSize = useWindowSize()
  let autoplay = true
  if (windowSize.width <= 1024) {
    autoplay = false
  }

  useEffect(() => {
    if (windowSize.width <= 1024) {
      autoplay = false
    }
  }, [windowSize])

  return (
    <section
      className="relative -mt-[40px] bg-blueDark lg:-mt-[135px]"
      data-section={data.section}
    >
      <div className="mask relative z-10 flex h-[85vh] min-h-[600px] w-full justify-center overflow-hidden bg-blueLight lg:h-screen lg:min-h-[700px]">
        <div id={data.section} className="absolute -top-20 lg:-top-4"></div>
        <div className="absolute left-0 z-under flex h-full w-full justify-center lg:top-[120px] lg:h-[calc(100%-120px)]">
          <Video
            bgLoop={true}
            autoplay={true}
            loading="lazy"
            loop={true}
            muted={true}
            player="self"
            className="hero-video image-height gatsby-hidden-mobile h-full w-auto object-center"
            source={data?.keyart?.video?.publicURL}
            poster={data.keyart.poster ? data.keyart.poster.publicURL : ''}
          />
          <StaticImage
            src="../../assets/img/logo-background.png"
            className="gatsby-absolute gatsby-hidden-mobile top-[35vh] h-auto -translate-y-1/2 opacity-50 mix-blend-screen md:w-[450px] lg:top-[45%] xxl:w-[800px] "
            loading="lazy"
          />
          <AnimatedBackground
            className="image-shape-overlay gatsby-hidden-mobile"
            translate={[0, -200]}
            direction="x"
          />
          <StaticImage
            src="../../assets/img/flippin-misfits-keyart.jpg"
            alt="Flippin Misfits"
            className="gatsby-hidden-desktop h-full w-full object-center"
            loading="lazy"
          />
        </div>
        {data.logo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
            className="gatsby-absolute top-[35vh] left-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center lg:top-[55%]"
          >
            <Image
              media={data.logo}
              className="anim-rotate h-auto w-[280px] fill-white drop-shadow-xl md:w-[350px] xxl:w-[500px]"
            />
          </motion.div>
        )}
      </div>
      <div className="absolute z-20 flex h-[85vh] min-h-[600px] w-full justify-center overflow-hidden top-0 lg:h-screen lg:min-h-[700px]">
        <div className="lg:mt-42 mx-auto flex w-full flex-col items-center justify-end px-12 pb-32 text-white lg:w-3/4 lg:items-end lg:justify-center lg:p-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.75 }}
            className="flex flex-col items-center space-y-10 lg:mt-40 lg:space-y-20"
          >
            {data.title && (
              <div className="text-highlight text-center font-sans text-[22px] font-bold uppercase tracking-wider text-tertiary lg:-mr-[180px] lg:-mb-4 lg:text-lg xl:text-xl">
                <Promotion
                  data={pageContext.gameData}
                  platformID="steam"
                  gameID="base-game"
                  display="text"
                  type="freeWeekEnd"
                />
              </div>
            )}
            {data.button && (
              <Button
                link={data.button.link}
                trigger={data.button.trigger}
                key={`heroBtn-1`}
                size="lg"
                style="primary button-cta lg:-mr-52"
                pageContext={pageContext}
              >
                <Markdown children={data.button.text} />
              </Button>
            )}
            {data.button2 && (
              <Button
                link={data.button2.link}
                trigger={data.button2.trigger}
                key={`heroBtn-2`}
                size="lg"
                style="secondary button-cta"
                pageContext={pageContext}
              >
                <Markdown children={data.button2.text} />
                {data.button2.icon && data.button2.icon !== null && (
                  <SVG
                    onError={(error) => console.log(error.message)}
                    cacheRequests={true}
                    preProcessor={(code) =>
                      code.replace(/fill=".*?"/g, 'fill="currentColor"')
                    }
                    src={data.button2.icon.publicURL}
                    className=""
                  />
                )}
              </Button>
            )}
          </motion.div>
        </div>
      </div>
      <AnimatedBackground className="pointer-events-none absolute right-0 -bottom-48 z-10 hidden h-auto w-[300px] xl:block xxl:-bottom-72 xxl:w-[400px]">
        <StaticImage
          src="../../assets/img/flippin-misfits-graffiti-1.png"
          alt="Flippin Misfits Graffiti"
          className="anim-float h-full w-full"
          loading="lazy"
        />
      </AnimatedBackground>
    </section>
  )
}

export default HeroBlock
