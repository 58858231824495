import React, { useState } from 'react'
import loadable from '@loadable/component'
import { config } from 'react-spring'
import Image from '@base/parts/image'
const Carousel = loadable(() => import('react-spring-3d-carousel'), { ssr: false })

const CharactersSlider = ({ data }) => {
  const isBrowser = typeof window !== 'undefined'

  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.stiff
  })

  let slides = []
  if (data !== null) {
    data.forEach((slide, i) => {
      let content = {
        key: i,
        content: <Image media={slide.image} className="characters-slide-image" />,
        className: `spring-carousel-slide spring-carousel-slide-${i}`
      }
      slides.push(content)
    })
  }

  let xDown = null
  let yDown = null

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    )
  }

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0]
    xDown = firstTouch.clientX
    yDown = firstTouch.clientY
  }

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return
    }
    let xUp = evt.touches[0].clientX
    let yUp = evt.touches[0].clientY
    let xDiff = xDown - xUp
    let yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        setState({
          goToSlide: state.goToSlide + 1,
          offsetRadius: 2,
          showNavigation: true,
          config: config.stiff
        })
      } else {
        setState({
          goToSlide: state.goToSlide - 1,
          offsetRadius: 2,
          showNavigation: true,
          config: config.stiff
        })
      }
    }
    xDown = null
    yDown = null
  }

  return (
    <div
      className="characters-slider w-full h-screen-3/4 lg:h-screen min-h-[400px] lg:min-h-[600px] max-h-[900px] box-border relative lg:-mb-32"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {isBrowser && (
        <Carousel
          slides={slides}
          goToSlide={state.goToSlide}
          offsetRadius={state.offsetRadius}
          showNavigation={state.showNavigation}
          animationConfig={state.config}
        />
      )}
    </div>
  )
}

export default CharactersSlider
