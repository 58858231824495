import React, { useEffect, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import { Video } from '@bhvr/web-design-system'

import ScrollDisplay from '@base/utils/helpers/scroll-display'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import AnimatedBackground from '@base/elements/animated-background'
import Markdown from '@base/parts/markdown'

import FeaturesSlider from '@elements/features-slider'

const FeaturesBlock = ({ data, pageContext }) => {
  const windowSize = useWindowSize()
  const featuresRef = useRef(null)
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState([0, false]) // video index (int.) and pause state (bool)

  let autoplay = true
  if (windowSize.width <= 1024) {
    autoplay = false
  }

  useEffect(() => {
    if (windowSize.width <= 1024) {
      autoplay = false
    }
  }, [windowSize])


  useEffect(() => {
    // Autoplay First video on page load
    if (autoplay) {
      featuresRef.current.childNodes.forEach((feature) => {
        let dataIndex = feature.getAttribute('data-index')
        let featureVideo = feature.querySelector('video')
        if (parseInt(dataIndex) === 0) {
          featureVideo.play()
        }
      })
    }
  }, [])

  function playVideo(index) {
    setCurrentVideoPlaying([index, false])

    featuresRef.current.childNodes.forEach((feature) => {
      let dataIndex = feature.getAttribute('data-index')
      let featurePlayBtn = feature.querySelector('.feature-play')
      let featureVideo = feature.querySelector('video')

      if (parseInt(dataIndex) === parseInt(index)) {
        if (featurePlayBtn.classList.contains('playing')) {
          // Pause Video
          setCurrentVideoPlaying([index, true])
          featureVideo.pause()
        } else {
          // Play video
          setCurrentVideoPlaying([index, false])
          featureVideo.play()
        }
      } else { // Pause every other videos
        featureVideo.pause()
      }
    })
  }

  return (
    <section
      className="relative -mt-[40px] lg:-mt-[135px] z-[1] overflow-hidden lg:overflow-visible"
      data-section={data.section}
    >
      <div className="relative h-full w-full overflow-hidden flex mask-reverse mask-offset-left-large gradient-dark-midnight gradient-corner-reverse flex-col justify-center items-center pb-72 lg:pb-32 pt-20 lg:pt-60 px-6 lg:px-0 box-border">
        <div id={data.section} className="absolute top-16 lg:top-20"></div>
        <ScrollDisplay loop={true} className="flex flex-col items-center w-full lg:w-3/4 xl:w-1/2 xxxl:w-1/3 lg:mb-20">
          <h2 className="text-primary text-center relative z-10 pt-20 pb-10">
            <Markdown
              children={data.title}
            />
          </h2>
          <p className="text-center text-white leading-normal font-bold">
            <Markdown
              children={data.text}
            />
          </p>
        </ScrollDisplay>
        <div ref={featuresRef} className="flex flex-row flex-wrap w-full xl:w-3/4 xxxl:w-2/3 text-white text-center">
          <FeaturesSlider data={data.features} />
          {data.features.map((feature, i) => (
            <div className="w-1/2 px-20 mb-24 box-border hidden lg:block" data-index={i}>
              <ScrollDisplay className="feature-image-wrapper">
                <div className="feature-image">
                  <button
                    className={`feature-play absolute w-full h-full left-0 top-0 cursor-pointer z-10 ${currentVideoPlaying[0] === i && currentVideoPlaying[1] === false ? 'playing' : 'paused'}`}
                    onClick={() => playVideo(i)}
                  >
                    <div className="feature-play-btn">
                      <svg viewBox="0 0 100 100">
                        <mask id={`button-mask-desktop-${i}`}>
                          <rect x="0" y="0" width="100" height="100" fill="white" />
                          <g transform="translate(20, 20) scale(1.65)">
                            {currentVideoPlaying[0] === i ? (
                              <motion.path
                                animate={{
                                  d: [
                                    `${currentVideoPlaying[1] === false ? ('M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28') : 'M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26'}`, // Pause icon path
                                    `${currentVideoPlaying[1] === false ? ('M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26') : 'M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28'}` // Play  icon path
                                  ]
                                }}
                                fill="black"
                                transition={{
                                  duration: 0.2,
                                }}
                              />
                            ) : (
                              <motion.path
                                animate={{
                                  d: [
                                    'M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26', // Play icon path
                                    'M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28', // Pause icon path
                                  ]
                                }}
                                fill="black"
                                transition={{
                                  duration: 0.2,
                                }}
                              />
                            )}
                          </g>
                        </mask>
                        <circle
                          className="fill-white"
                          cx="50"
                          cy="50"
                          r="50"
                          mask={`url(#button-mask-desktop-${i})`}
                        />
                      </svg>
                    </div>
                  </button>
                  <Video
                    autoplay={false}
                    loading="lazy"
                    loop={true}
                    muted={true}
                    player="self"
                    className="video-embed corners-content drop-shadow-md bg-black absolute top-0 h-full w-full"
                    source={feature?.video?.publicURL}
                    poster={feature?.poster?.publicURL}
                  />
                </div>
              </ScrollDisplay>
              <ScrollDisplay className="px-10">
                <h3 className="font-display mt-20 mb-6">{feature.title}</h3>
                <p>{feature.description}</p>
              </ScrollDisplay>
            </div>
          ))}
        </div>
        <AnimatedBackground className="absolute w-[250px] h-auto -left-20 -bottom-60 pointer-events-none z-0 block lg:hidden">
          <StaticImage
            src="../../assets/img/flippin-misfits-graffiti-1.png"
            alt="Flippin Misfits Graffiti"
            className="h-full w-full anim-float"
            loading="lazy"
          />
        </AnimatedBackground>
      </div>
      <AnimatedBackground className="absolute w-[300px] xxl:w-[400px] h-auto left-0 -bottom-72 xxl:-bottom-96 pointer-events-none z-10 hidden lg:block">
        <StaticImage
          src="../../assets/img/flippin-misfits-graffiti-5.png"
          alt="Flippin Misfits Graffiti"
          className="h-full w-full anim-float"
          loading="lazy"
        />
      </AnimatedBackground>
    </section>
  )
}

export default FeaturesBlock
