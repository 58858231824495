import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'
import Markdown from '@base/parts/markdown'
import twitterIcon from '../../assets/icons/twitter.svg'

const TweetCard = (props) => {
  if (!props.data) {
    return ''
  }
  let tweetText = props.data.text

  // Get all hardcoded URL from the tweet's text
  let tweetLinks = tweetText.match(/\bhttps?:\/\/\S+/gi)

  // Get the tweet's direct link (it's the very last URL returned in the text)
  let tweetUrl = tweetLinks[tweetLinks.length - 1]

  // Get the tweet's main image
  let tweetImageURL = null
  if (props.image && props.image.length > 0) {
    let tweetImage = props.image[0]
    tweetImageURL = tweetImage.url ? tweetImage.url : (tweetImage.preview_image_url ? tweetImage.preview_image_url : null) // fallback to preview image for videos
  }

  // Clean text to remove hardcoded URLs and Hashtags
  tweetText = tweetText.replace(tweetUrl, '')
  tweetText = tweetText.replace(tweetLinks[0], `<a href="${tweetLinks[0]}" target="_blank">${tweetLinks[0]}</a>`)
  tweetText = tweetText.replace('#FlippinMisfits', `<a href="https://twitter.com/hashtag/FlippinMisfits" target="_blank">#FlippinMisfits</a>`)

  return (
    <article className={props.className}>
      <div className="flex items-center justify-between mb-12">
        <div className="flex items-center">
          <div className="rounded-full w-20 h-20 bg-primary overflow-hidden relative">
            <StaticImage
              src="../../assets/img/flippin-misfits-twitter-avatar.jpg"
              alt="Flippin Misfits Twitter Avatar"
              className="h-full w-full gatsby-absolute"
              loading="lazy"
            />
          </div>
          <div className="ml-4">
            <p className="mt-0 mb-2 font-bold tracking-wide">Flippin Misfits</p>
            <span className="tracking-wide">@FlippinMisfits</span>
          </div>
        </div>
        <a
          href="https://twitter.com/FlippinMisfits"
          className="w-16 h-16 bg-[#1da1f2] rounded-full flex justify-center items-center hover:bg-black transition-all duration-500"
          target="_blank"
          rel="noreferrer"
        >
          <div className="social-link max-w-[100px] flex justify-center items-center text-white">
            <SVG
              onError={error => console.log(error.message)}
              cacheRequests={true}
              preProcessor={code =>
                code.replace(/fill=".*?"/g, 'fill="currentColor"')
              }
              src={twitterIcon}
              className=""
            />
          </div>
        </a>
      </div>
      <p className="text-sm leading-loose tracking-wide">
        <Markdown children={tweetText} />
      </p>
      {tweetImageURL !== null && (
        <img
          src={tweetImageURL}
          className="w-full mb-8"
        />
      )}
      <a href={tweetUrl} className="link" target="_blank">Read more</a>
    </article>
  )
}

export default TweetCard