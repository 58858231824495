import React, { useEffect } from 'react'
import ScrollDisplay from '@base/utils/helpers/scroll-display'
import { StaticImage } from 'gatsby-plugin-image'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import AnimatedBackground from '@base/elements/animated-background'
import Markdown from '@base/parts/markdown'
import AnimatedDots from '@elements/animated-dots'
import CharactersSlider from '@elements/characters-slider'

const CharactersBlock = ({ data, pageContext }) => {
  const windowSize = useWindowSize()
  let autoplay = true
  if (windowSize.width <= 1024) {
    autoplay = false
  }

  useEffect(() => {
    if (windowSize.width <= 1024) {
      autoplay = false
    }
  }, [windowSize])

  return (
    <section
      className="relative bg-blueDark"
      data-section={data.section}
    >
      <div className="relative h-full w-full flex mask mask-offset-left mask-gradient-black flex-col justify-center items-center px-6 lg:px-0 box-border pt-[70vh] -mt-[70vh] bg-[#01d2fd]">
        <div id={data.section} className="absolute top-[68vh] lg:top-[60vh]"></div>
        <ScrollDisplay className="flex flex-col items-center w-full lg:w-3/4 xxl:w-1/3 mb-20 px-10 lg:px-0 box-border">
          <h2 className="text-blueDark text-center relative z-10 pt-20 pb-10">
            <Markdown
              children={data.title}
            />
          </h2>
          <p className="text-center text-blueDark leading-normal font-bold">
            <Markdown
              children={data.text}
            />
          </p>
        </ScrollDisplay>
        <ScrollDisplay className="flex flex-row flex-wrap w-full lg:w-3/4 xxxl:w-2/3 text-white text-center lg:px-20 box-border" delay="0.25">
          <CharactersSlider
            data={data.characters}
          />
        </ScrollDisplay>
        <ScrollDisplay className="w-full gatsby-hidden-mobile" delay="0.25">
          <AnimatedDots />
        </ScrollDisplay>
        <AnimatedBackground className="absolute w-full h-full left-0 bottom-0 pointer-events-none z-under box-border">
          <StaticImage
            src="../../assets/img/background-blue.jpg"
            alt=""
            className="h-full w-full gatsby-absolute pointer-events-none z-under -bottom-[20%] object-bottom mask-fade-top"
            backgroundColor="#040926"
            loading="lazy"
          />
        </AnimatedBackground>
      </div>
      <AnimatedBackground className="absolute w-[100px] lg:w-[150px] xxl:w-[220px] h-auto right-2 lg:right-20 -bottom-12 lg:-bottom-32 xxl:-bottom-48 pointer-events-none z-10">
        <StaticImage
          src="../../assets/img/flippin-misfits-graffiti-6.png"
          alt="Flippin Misfits Graffiti"
          className="h-full w-full anim-float"
          loading="lazy"
        />
      </AnimatedBackground>
    </section>
  )
}

export default CharactersBlock
